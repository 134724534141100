import React, {useEffect} from "react"
import { navigate } from "gatsby"

// markup
const NotFoundPage = () => {
  useEffect(() => {
    navigate("/menu");
  });
  return (
    <h1>404</h1>
  )
}

export default NotFoundPage
